export const cardItemsData = [
  {
    id: 1,
    name: "Food Card",
    description: "This card is used for spending on Food merchants",
    final_price: 21,
    original_price: 30,
    img_url:
      "https://react-coding-assignment.s3.ap-south-1.amazonaws.com/cards/orange_card.png",
  },
  {
    id: 2,
    name: "Travel Card",
    description: "This card is used for spending on Travel and hotel bookings",
    final_price: 20,
    img_url:
      "https://react-coding-assignment.s3.ap-south-1.amazonaws.com/cards/blue_card.png",
  },
  {
    id: 3,
    name: "Epic Card",
    description: "Use this card and get benefits on every transaction",
    final_price: 40,
    img_url:
      "https://react-coding-assignment.s3.ap-south-1.amazonaws.com/cards/golden_card.png",
  },
  {
    id: 4,
    name: "Happay Premium Card",
    description: "Use this card and get benefits on every transaction",
    final_price: 40,
    img_url:
      "https://react-coding-assignment.s3.ap-south-1.amazonaws.com/cards/black_card.png",
  },
];
